import axios from "axios";
import qs from "qs";
import {
	Loading
} from 'element-ui';
const install = Vue => {
	Vue.prototype.apis = {
		// 接口
		// banner:'api/Index/banner_list'
	};
	let loadingIsShow = null;
	var instance = axios.create({
		baseURL: 'https://hygl.hljcy.cn',
		timeout: 3600000,
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			// "Content-Type": "application/json",
		},
	});

	// 添加请求拦截器
	instance.interceptors.request.use(config => {
		// config.headers["token"] = localStorage.getItem("token");
		//config.headers["token"] = '0371842491dcb05154d64fa43d9abc2a'	
		
		// 再次设置tkoen或者添加loading等请求前的操作
		// 添加一个loading
		// loadingIsShow = Loading.service({
		// 	lock: true,
		// 	text: "Loading",
		// 	spinner: "el-icon-loading",
		// 	background: "rgba(0, 0, 0, 0.7)"
		// });
		return config;
	});

	// // 添加xi响应拦截器
	instance.interceptors.response.use(
		response => {
			//响应数据后做点什么
			// 请求成功关闭loading
			// console.log("请求成功");
			// loadingIsShow.close();
			return response;
		},
		error => {
			// 对响应错误做点什么
			return Promise.reject(error);
		}
	);

	Vue.prototype.http = function(url, params = {}) {
		const that = this;
		if(localStorage.getItem('isgethttp')){
			params.isgethttp=localStorage.getItem('isgethttp')||'';
		}
		params.token = localStorage.getItem("token") || ''
		params.subaccount = localStorage.getItem("subaccount") || 0
		return new Promise((resolve, reject) => {
			instance
				.post(url,qs.stringify(params) )
				.then(res => {
					if (res.data.status != 1 && res.data.status != -200) {
						this.$message({
							showClose: true,
							message: res.data.message,
							type: 'warning'
						});
					}
					if(res.data.status == -200){
						localStorage.clear();
						this.$router.push({path:"/"});
					}
					
					resolve(res.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	};
};
export default install;
