import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
VueRouter.prototype.replace = function push(location) {
	return originalReplace.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
const routes = [{
	path: '/',
	name: '/',
	component: () => import( /* 登录 */ '../views/login')
}, {
	path: '/forgotpsd',
	name: 'forgotpsd',
	component: () => import( /* 登录 */ '../views/forgotpsd')
}, {
	path: '/store_info',
	name: 'store_info',
	component: () => import( /* 门店首页 */ '../views/layout'),
	children: [{
		path: '/store_info',
		name: 'store_info',
		component: () => import( /* 店铺中心 */ '../views/store/store_layout'),
		children: [{
			path: '/store_info',
			name: 'store_info',
			component: () => import( /* 店铺中心 */ '../views/store/store_info'),
		}, {
			path: '/store_info_edit',
			name: 'store_info_edit',
			component: () => import( /* 店铺中心编辑 */ '../views/store/store_info_edit'),
		}, {
			path: '/store_list',
			name: 'store_list',
			component: () => import( /* 门店列表 */ '../views/store/store_list'),
		}, {
			path: '/store_post_list',
			name: 'store_post_list',
			component: () => import( /* 岗位设置 */ '../views/store/store_post_list'),
		}, {
			path: '/store_staff_list',
			name: 'store_staff_list',
			component: () => import( /* 员工设置 */ '../views/store/store_staff_list'),
		}, {
			path: '/store_staff_list_add',
			name: 'store_staff_list_add',
			component: () => import( /* 员工设置 */ '../views/store/store_staff_list_add'),
		}, {
			path: '/store_powers',
			name: 'store_powers',
			component: () => import( /* 员工权限 */ '../views/store/store_powers'),
		}, ]
	}, ]
}, {
	path: '/goods',
	name: 'goods',
	component: () => import( /* 商品首页 */ '../views/layout'),
	children: [{
		path: '/goods',
		name: 'goods',
		component: () => import( /* 店铺中心 */ '../views/store/store_layout'),
		children: [{
			path: '/goods',
			name: 'goods',
			component: () => import( /* 商品列表 */ '../views/goods/goods'),
		}, {
			path: '/goods_add',
			name: 'goods_add',
			component: () => import( /* 商品新加 */ '../views/goods/goods_add'),
		}, {
			path: '/goods_class',
			name: 'goods_class',
			component: () => import( /* 商品分类 */ '../views/goods/goods_class'),
		}, {
			path: '/goods_details',
			name: 'goods_details',
			component: () => import( /* 商品详情 */ '../views/goods/goods_details'),
		}, ]
	}]
}, {
	path: '/members',
	name: 'members',
	component: () => import( /* 会员中心 */ '../views/layout'),
	children: [{
		path: '/members',
		name: 'members',
		component: () => import( /* 会员中心 */ '../views/store/store_layout'),
		children: [{
			path: '/members/members',
			name: 'members',
			component: () => import( /* 会员列表 */ '../views/members/members'),
		}, {
			path: '/members/members_add',
			name: 'members_add',
			component: () => import( /* 新增会员 */ '../views/members/members_add'),
		}, {
			path: '/members/second_card',
			name: 'second_card',
			component: () => import( /* 次卡购买 */ '../views/members/second_card'),
		}, {
			path: '/members/money',
			name: 'money',
			component: () => import( /* 会员充值 */ '../views/members/money'),
		}, {
			path: '/members/members_info',
			name: 'members_info',
			component: () => import( /* 会员信息 */ '../views/members/members_info'),
		}, {
			path: '/members/members_card',
			name: 'members_card',
			component: () => import( /* 会员补卡 */ '../views/members/members_card'),
		}, {
			path: '/members/second_card_edit',
			name: 'second_card_edit',
			component: () => import( /* 次卡编辑 */ '../views/members/second_card_edit'),
		}]
	}]
}, {
	path: '/secondCard',
	name: 'secondCard',
	component: () => import( /* 次卡 */ '../views/layout'),
	children: [{
		path: '/secondCard/secondCard',
		name: 'secondCard',
		component: () => import( /* 次卡首页 */ '../views/secondCard/secondCard')
	}]
}, {
	path: '/buckle',
	name: 'buckle',
	component: () => import( /* 扣次 */ '../views/layout'),
	children: [{
		path: '/buckle/buckle',
		name: 'buckle',
		component: () => import( /* 扣次首页 */ '../views/buckle/buckle')
	}, {
		path: '/buckle/list',
		name: 'list',
		component: () => import( /* 扣次列表 */ '../views/buckle/list')
	}]
}, {
	path: '/overview',
	name: 'overview',
	component: () => import( /* 概况 */ '../views/layout'),
	children: [{
		path: '/overview/overview',
		name: 'overview',
		component: () => import( /* 概况 */ '../views/overview/overview')
	}]
}, {
	path: '/reportforms',
	name: 'reportforms',
	component: () => import( /* 报表 */ '../views/layout'),
	children: [{
		path: '/reportforms/home',
		name: 'home',
		component: () => import( /* 店铺中心 */ '../views/store/store_layout'),
	
	children: [{
		path: '/reportforms/home',
		name: 'home',
		component: () => import( /* 报表首页 */ '../views/reportforms/home')
	}, {
		path: '/reportforms/check_day',
		name: 'check_day',
		component: () => import( /* 每日对账单 */ '../views/reportforms/check_day')
	}, {
		path: '/reportforms/store_overview',
		name: 'store_overview',
		component: () => import( /* 门店概况 */ '../views/reportforms/store_overview')
	}, {
		path: '/reportforms/flowing_water',
		name: 'flowing_water',
		component: () => import( /* 销售流水表 */ '../views/reportforms/flowing_water')
	}, {
		path: '/reportforms/return_report',
		name: 'return_report',
		component: () => import( /* 退货报表 */ '../views/reportforms/return_report')
	}, {
		path: '/reportforms/mori',
		name: 'mori',
		component: () => import( /* 销售毛利分析 */ '../views/reportforms/mori')
	}, {
		path: '/reportforms/recharge_list',
		name: 'recharge_list',
		component: () => import( /* 充值报表 */ '../views/reportforms/recharge_list')
	}, {
		path: '/reportforms/balance',
		name: 'balance',
		component: () => import( /* 会员余额变动 */ '../views/reportforms/balance')
	}, {
		path: '/reportforms/rfm_analysis',
		name: 'rfm_analysis',
		component: () => import( /* RFM分析 */ '../views/reportforms/rfm_analysis')
	}, {
		path: '/reportforms/time_card',
		name: 'time_card',
		component: () => import( /* 次卡消费流水 */ '../views/reportforms/time_card')
	}, {
		path: '/reportforms/surplus',
		name: 'surplus',
		component: () => import( /* 剩余次数汇总 */ '../views/reportforms/surplus')
	}, {
		path: '/reportforms/sales_card',
		name: 'sales_card',
		component: () => import( /* 售卡明细表 */ '../views/reportforms/sales_card')
	}, {
		path: '/reportforms/summary',
		name: 'summary',
		component: () => import( /*售卡汇总表 */ '../views/reportforms/summary')
	}, {
		path: '/reportforms/download',
		name: 'download',
		component: () => import( /*报表下载 */ '../views/reportforms/download')
	}],
	}]
}, {
	path: '/bill_home',
	name: 'bill_home',
	component: () => import( /* 账单 */ '../views/layout'),
	children: [{
		path: '/bill/bill_home',
		name: 'bill_home',
		component: () => import( /* 账单首页 */ '../views/bill/bill_home')
	}, ]
},{
	path: '/setup',
	name: 'setup',
	component: () => import( /* 设置 */ '../views/layout'),
	children: [{
		path: '/setup/setuphome',
		name: 'setuphome',
		component: () => import( /* 设置首页 */ '../views/setup/setuphome')
	}, ]
}, ]
const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})
router.afterEach((to, from, next) => {
	window.scrollTo(0, 0);
	// chrome
	document.body.scrollTop = 0
	// firefox
	document.documentElement.scrollTop = 0
	// safari
	window.pageYOffset = 0
})
export default router