const navList = [],leftList=[], images='',oneTimeShow=false,printerIsopen=1,printerNum=1;

export default{
    navList,    //地址
	leftList,
	images,
	oneTimeShow,
	printerIsopen,
	printerNum
}
